<template>
  <Dropdown :open="isOpen" @open="isOpen = true" @close="isOpen = false">
    <template #trigger>
      <PanelNavigationButton
        class="bg-primary-dark-2/5 dark:bg-primary-light-2/5 !w-11 min-w-0 !px-0"
        :text="'...'"
        @click="isOpen = !isOpen"
      />
    </template>

    <template #default>
      <div class="flex flex-col px-4 md:max-h-96">
        <button
          v-for="{ key, title } in visibleSteps"
          :key
          class="py-1"
          @click="handleClick(key)"
        >
          {{ title }}
        </button>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import type { VoucherShopStepKey } from '@voucher-shop/types/voucher-shop'
import type { Panel } from '@booking/types/panel-manager'

interface Props {
  panels: Panel[]
  setPanel: (panel: VoucherShopStepKey, options?: unknown) => void
  step: string
}

const { panels, setPanel, step } = defineProps<Props>()

function handleClick(key: string) {
  setPanel(key as VoucherShopStepKey)
  isOpen.value = false
}

const currentStepIndex = computed(() => panels.findIndex((s) => s.key === step))
const visibleSteps = computed(() => panels.slice(0, currentStepIndex.value))

const isOpen = ref(false)
defineOptions({
  name: 'PanelNavigationDropdown',
})
</script>
