<script setup lang="ts">
import { PANEL_NAVIGATION_ITEM_TYPE } from '@booking/constants/panel'
import type {
  Panel,
  PanelNavigationItemType,
} from '@booking/types/panel-manager'
import type { VoucherShopStepKey } from '@voucher-shop/types/voucher-shop'
import { breakpointsTailwind } from '@vueuse/core'

const { PANEL, SPACER, DROPDOWN } = PANEL_NAVIGATION_ITEM_TYPE

interface Props {
  panels: Panel[]
  setPanel: (panel: VoucherShopStepKey, options?: any) => void
  setInitialPanel: () => void
  activePanel?: Panel
  step: string
}

const props = defineProps<Props>()
const { icons } = useDesign()

function handleClick(key: string, index: number) {
  if (index === 0) {
    props.setInitialPanel()
  } else {
    props.setPanel(key as VoucherShopStepKey)
  }
}

interface NavigationItem {
  type: PanelNavigationItemType
  key?: string
  title?: string
  classes?: Record<string, boolean>
}

const breakpoints = useBreakpoints(breakpointsTailwind)
const mdAndLarger = breakpoints.greaterOrEqual('md')

const currentStepIndex = computed(() =>
  props.panels.findIndex((panel) => panel.key === props.step),
)

const hideNav = computed(() => props.activePanel?.hideNav ?? false)

const visiblePanels = computed(() =>
  props.panels
    .filter(
      (panel, index) =>
        panel.key === props.step ||
        (!panel.hideFromNav && index <= currentStepIndex.value),
    )
    .map((panel) => ({
      ...panel,
      title:
        props.activePanel?.key === panel.key
          ? props.activePanel.title
          : panel.title,
    })),
)

const navigationItems = computed<NavigationItem[]>(() => {
  const items: NavigationItem[] = []

  const spacerClasses = 'mx-1 size-6 fill-current'

  items.push({
    type: DROPDOWN,
    classes: {
      'ml-4': true,
      hidden: visiblePanels.value.length <= 2,
      'md:hidden': true,
    },
  })

  visiblePanels.value
    .slice(mdAndLarger.value ? 0 : -2)
    .forEach((panel, index) => {
      items.push({
        type: SPACER,
        classes: {
          [spacerClasses]: true,
          'md:hidden': index === 0,
          hidden: index === 0 && visiblePanels.value.length <= 2,
        },
      })

      items.push({
        type: PANEL,
        key: panel.key,
        title:
          panel.key === props.activePanel?.key
            ? props.activePanel.title
            : panel.title,
        classes: {
          'shrink-0': index !== visiblePanels.value.length - 1,
          'hidden md:block': false,
        },
      })
    })

  return items
})

defineOptions({
  name: 'PanelNavigation',
})
</script>

<template>
  <div
    v-if="navigationItems.length && !hideNav"
    class="-ml-4 flex h-11 items-center overflow-hidden"
  >
    <template v-for="(item, index) in navigationItems" :key="index">
      <PanelNavigationDropdown
        v-if="item.type === DROPDOWN"
        :panels
        :set-panel
        :step
        :class="item.classes"
      />
      <PanelNavigationButton
        v-else-if="item.type === PANEL"
        :text="item.title ?? ''"
        :active="item.key === activePanel?.key"
        :class="item.classes"
        @click="handleClick(item.key ?? '', index)"
      />
      <Icon
        v-else-if="item.type === SPACER"
        :name="icons.CHEVRON_RIGHT"
        :class="item.classes"
      />
    </template>
  </div>
</template>
